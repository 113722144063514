.contact-container label {
    padding: 0.5rem 0;
    display: block;
}

.contact-row {
    display: flex;
    justify-content: flex-start;
}

.input {
    border-width: 1px;
    line-height: normal;
}

.textarea {
    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0375rem;
    text-decoration: none;
    text-transform: initial;
    text-align: left;
    color: #575757;
    border-width: 0.1rem;
    border-style: solid;
    border-radius: 0.25rem;
    border-color: #dadada;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: none;
    transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, transform 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    margin: 0;
    padding: 1rem;
    height: 150px;
    min-width: 100%;
    max-width: 100%;
    position: relative;
    outline: none;
}



