.header {
    height: calc(100vh - 4rem);
    background: url('https://source.unsplash.com/RJ50EHk3pC4/1600x900');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.header-row {
    height: calc(100vh - 4rem);
    display: flex;
    align-items: center;
}

.header-col h2,
.header-col p {
    color: #ffffff;
}